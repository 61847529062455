/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
//import "./src/assets/css/main.css";
import "./src/assets/sass/main.scss";
import "react-toastify/dist/ReactToastify.css";
import 'react-spring-bottom-sheet/dist/style.css';
import "./src/assets/css/bulma-tooltip.min.css";
import "@creativebulma/bulma-collapsible/dist/css/bulma-collapsible.min.css";


import React from 'react';
import RootElement from './src/components/RootElement';

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};

const { navigate } = require("gatsby");

// this is a improvements
export const onPreRouteUpdate = ({ location, prevLocation }) => {
  // check if we are at event 
  const eventPathName = prevLocation?.pathname.toString().indexOf('/event/');
  if (eventPathName !== -1 && prevLocation?.pathname !== location.pathname) {
    const path = location.pathname + location.search + location.hash;
    navigate(path, {
      replace: true
    });
  }
};