import { createSlice } from '@reduxjs/toolkit';

const groupReducer = createSlice({
  name: 'groupReducer',
  initialState: 'categories',
  reducers: {
    setGroup: (_, actions) => actions.payload,
  },
});

export const { setGroup } = groupReducer.actions;

export default groupReducer.reducer;