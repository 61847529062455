import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import store from '../store/redux/store';

const queryClient = new QueryClient();
export const QUERY_CACHE_TIME = 1000 * 60 * 5; // in ms

export const client = new QueryClient({
  defaultOptions: {
    queries: { staleTime: QUERY_CACHE_TIME },
    mutations: {
      retry: (attempts, error) => attempts < 3 && (error).response?.status === 502,
    },
  },
});

const RootElement = ({ children }) => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  );
};

export default RootElement;